import React, {Component} from 'react';
import Facade from "./Facade";
import Utils from "./Utils";
import "./ChangeResult.css";

export default class ChangeResult extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
    // result : PropTypes.array// array
  };

  constructor(props)
  {
    super(props);
    this.state = {result:null};
    this.nextBgAlt = false;
  }

  componentWillUnmount()
  {
  }

  componentWillMount()
  {
  }

  componentDidMount()
  {
    this.setResult(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext)
  {
    this.setResult(nextProps.result);
  }

  setResult ( result )
  {
    this.setState({result});
  }

  render()
  {
    if ( this.state.result  && this.state.result.length>0)
    {
      return (
        <div className="ChangeResult" ref={(ref)=>{
          if(ref && ref.offsetTop)
            window.scrollTo(0, ref.offsetTop);
        }}>
          <img className="ChangeResultTop" src={require("./res/recommend.png")} alt=""/>
          <div className="sections">
            {this.state.result.map((li, i) => {
              return this.renderResultLi(li)
            })}
          </div>
          <a className="primaryBtn" href={Facade.GOTO_DOWNLOAD_URL}>立即注册</a>
        </div>);
    }else{
      return null;
    }
  }

  renderResultLi(id)
  {
    switch (id)
    {
      case 1:
        return this.renderLi(1,"降噪沟通，拒绝无关消息刷屏", "相关消息自动“串”联（无需费力翻找聊天记录）、快捷表情静音回复（再也不用被“收到”刷屏）、高频会话置顶显示（再也不用担心群组管理混乱）"
          , require("./res/feishu1.mp4"), require("./res/feishu1-0001.png"));
        break;
      case 2:
        return this.renderLi(2,"音视频会议,远程协作如临现场", "多方视频稳定接入（单个视频会议支持100方稳定接入）、一键发起百万人直播（最多支持百万级观众同时在线，轻松搞定培训、全员分享）、便捷屏幕共享与互动（支持飞书云文档原生共享，可以直接通过屏幕编辑共享文档）"
          , require("./res/feishu4.mp4"), require("./res/feishu2-0001.png"));
        break;
      case 3:
        return this.renderLi(3,"云文档，实时协作和知识管理利器", "可多人实时协同编辑的在线文档（不仅是文档，更是丰富的创作和互动工具） 、支持强大数据统计的在线表格（满足办公场景下数据统计、分析等需求）、激发无限创造力的思维笔记（有效整理逻辑思路，激发无限创造力）"
          , require("./res/feishu2.mp4"), require("./res/feishu3-0002.png"));
        break;
      case 4:
        return this.renderLi(4,"智能日历，高效组织团队会议", "高效组织团队会议（快速向同事或整个群组发出邀约）、 便捷订阅同事日历（直观查看同事忙闲状态，一键预约）、 极 速 查 找 空 闲 会 议 室 （排除被占用，可用会议室一目了然）、智能日程通知提醒（日历助手小机器人及时发送提醒，不错过任何会议）"
          , require("./res/feishu3.mp4"), require("./res/feishu1-0001.png"));
        break;
      case 5:
        return this.renderLiImg(5,"智能机器人，高效任务管理小助手", "智能小助手提效降负（机器人小助手，降低日常维护性和重复性工作，提升工作效率和满意度）、自动推送多方消息提醒（机器人自动推送集成应用各类动态推送到群聊中 ， 多方信息及时掌控）", require("./res/robot.jpeg"));
        break;
      case 6:
        return this.renderLiImg(6,"ORK，快速对齐目标，高效推进项目", "聚焦重点，对齐方向（公开查询并关注他人 OKR，了解更多背景信息）、即时反馈，优化策略（可视化数据看板，复盘全周期 OKR 执行情况）", require("./res/okr.jpg"));
        break;
      case 7:
        return this.renderLiImg(7,"服务台，集合内部智能服务号的平台", "采用了智能客服和人工客服结合为企业服务的方式。企业员工可自助通过服务台解决疑问。", require("./res/service.png"));
        break;
      case 8:
        return this.renderLi(8,"丰富的第三方功能（工作台）", "多方视频稳定接入（单个视频会议支持100方稳定接入）、一键发起百万人直播（最多支持百万级观众同时在线，轻松搞定培训、全员分享）、便捷屏幕共享与互动（支持飞书云文档原生共享，可以直接通过屏幕编辑共享文档）"
          , require("./res/feishu5.mp4"));
        break;
    }
  }



  renderLi( key,h1Str , pStr , video,poster)
  {
    this.nextBgAlt= !this.nextBgAlt;
    let bgClassName = this.nextBgAlt? "bg2":"bg1";
    // Facade.device.
    return (
      <div className={bgClassName} key={key}>
        <div className="h2">{h1Str}</div>
        <div className="para">
          {pStr}
        </div>
        <video src={video} loop="loop"
               muted playsinline={true}
               autoPlay={true}
               controls={true}
               // autoPlay="autoplay"
               // controls={true}  /*这个属性规定浏览器为该视频提供播放控件*/
               // style={{objectFit: "fill"}}  /*加这个style会让 Android / web 的视频在微信里的视频全屏，如果是在手机上预览，会让视频的封面同视频一样大小*/
               webkit-playsinline="true"  /*这个属性是ios 10中设置可以让视频在小窗内播放，也就是不是全屏播放*/
               x-webkit-airplay="true"  /*这个属性还不知道作用*/
               playsInline  /*IOS微信浏览器支持小窗内播放*/
               x5-video-player-type="h5" /*启用H5播放器,是wechat安卓版特性*/
               x5-video-orientation="h5" /*播放器支付的方向，landscape横屏，portraint竖屏，默认值为竖屏*/
               x5-video-player-fullscreen="true" /*全屏设置，设置为 true 是防止横屏*/
               x5-playsinline="" webkit-playsinline=""
               poster={poster}
               preload="auto" /*这个属性规定页面加载完成后载入视频*/
        />

      </div>
    );
  }
  renderLiImg(  key,h1Str , pStr , img)
  {
    this.nextBgAlt= !this.nextBgAlt;
    let bgClassName = this.nextBgAlt? "bg2":"bg1";
    return (
      <div className={bgClassName} key={key}>
        <div className="h2">{h1Str}</div>
        <div className="para">
          {pStr}
        </div>
        <img src={img} />

      </div>
    );
  }

}