import Facade from "./Facade";
import Utils from "./Utils";

export default class DFModel
{
  constructor()
  {

  }

  //首屏风格  1 打坐冥想   2 飞飞机
  firstScreenType = 1;


}