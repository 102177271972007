import React, {Component} from 'react';
import "./FirstScreen.css";
import Facade from "./Facade";
import MusicBtn from "./MusicBtn";

export default class FirstScreen extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {}
  }
  nextR = 0;

  componentDidMount()
  {
    let type = Facade.model.firstScreenType;
    if (type==2)
    {
      window.document.writeln("\n" +
        "    <audio loop id=\"music\" preload=\"auto\"  autoplay=\"autoplay\"  onload=\"onLoadBgm()\">\n" +
        "      <source src=\"https://cdn.iuxlabs.com/digitalforces/digital-force-web/bgm-export2.mp3\" type=\"audio/mpeg\">\n" +
        "    </audio>");
    }else{
      window.document.writeln("\n" +
        "    <audio loop id=\"music\" preload=\"auto\"  autoplay=\"autoplay\"  onload=\"onLoadBgm()\">\n" +
        "      <source src=\"https://cdn.iuxlabs.com/digitalforces/digital-force-web/bgm-export.mp3\" type=\"audio/mpeg\">\n" +
        "    </audio>");
    }
  }

  render()
  {
    let syncWinH = Facade.screenHelper.getWinH();

    let extraTop = syncWinH - 1207;//<-1207是设计稿尺寸
    let extraStyle = {};
    if (extraTop > 0)
    {
      extraStyle = {marginTop: extraTop / 2};
    }
    let type = Facade.model.firstScreenType ;
    return (
      <div id="FirstScreen" className={"FirstScreen type"+type} style={{minHeight: syncWinH}}>
        <div className="Logo">
          <img src={require("./res/logo.png")} alt=""/>
        </div>
        <MusicBtn/>

        <img className="sky" src={require("./res/banner_sky.png")} alt=""/>
        {this.spawnMeters(8)}
        {type==2 ?
          (<img className="front" src={require("./res/banner_front2-05.png")} alt=""/>)
          :
          (<img className="front" src={require("./res/banner_front.png")} alt=""/>)}
        {type==2 && <img className="airplane" alt="" src={require("./res/airplane.png")} /> }


        <div className="content">
          <div className="h1">颠覆的时代</div>

          <Element icon={require("./res/virus.png")} p1="疫情" p2="新场景 新协作" className="element1" spawnDelay={500} extraStyle={extraStyle}/>
          <Element icon={require("./res/technology.png")} p1="新技术驱动" p2="新技术 新玩法 新设施" className="element2" spawnDelay={1000} extraStyle={extraStyle}/>
          <Element icon={require("./res/user.png")} p1="用户为中心" p2="新用户 新行为" className="element3" spawnDelay={1500} extraStyle={extraStyle}/>
          <Element icon={require("./res/revolution.png")} p1="组织必革新" p2="新形态 新认知 新关系" className="element4" spawnDelay={2000} extraStyle={extraStyle}/>

        </div>

      </div>);
  }

  spawnMeters(num)
  {
    let perDelay = 4 / num;
    let end = [];
    for (let i = 0; i < num; i++)
    {

      let randomStyle = {
        left: Math.random() * 500 - 170,
        animationDelay: ((this.nextR++) * perDelay) + "s"
      };
      end.push(
        <img key={i} src={Meters[(i%5)]} alt="" className="meter" style={randomStyle}/>
      )
    }
    return end;
  }



}

const Meters = [
  require("./res/meteor1.png"),
  require("./res/meteor2.png"),
  require("./res/meteor3.png"),
  require("./res/meteor4.png"),
  require("./res/meteor5.png")
];

class Element extends  Component {

  constructor(props)
  {
    super(props);
    this.fullStr = props.str;
    this.index = 0;
    this.state = { str : "",
      showEle:false};
  }

  componentDidMount()
  {
    clearTimeout(this.itv);
    this.itv=setTimeout(()=>{this.setState({showEle:true})},this.props.spawnDelay);
  }

  render()
  {
    return (
    <div className={"element "+ (this.props.className)+(this.state.showEle?" show":"")} style={this.props.extraStyle}>
      <img src={this.props.icon} alt=""/>
      <div className="p1">{this.props.p1}</div>
      {this.state.showEle && <GrowText str={this.props.p2} />}
    </div>
    );

  }
}


class GrowText extends Component
{

  constructor(props)
  {
    super(props);
    this.fullStr = props.str;
    this.index = 0;
    this.state = { str : ""};
  }

  componentDidMount()
  {
    // this.itv = setInterval( ()=>{
    //   let str = this.fullStr.substr(0,this.index) +RandomFontGlitch.substr(Math.random()*RandomFontGlitch.length,1)+this.fullStr.substr(this.index+1);
    //   if ( ++this.index > this.fullStr.length)
    //   {
    //     this.index=0;
    //   }
    //   this.setState({str });
    // },100);
    this.beginInterval = setTimeout( this.growIn.bind(this) , 1000);
  }

  growIn()
  {
    this.itv = setInterval( ()=>{
      let str = this.fullStr.substr(0,this.index) +RandomFontGlitch.substr(Math.random()*RandomFontGlitch.length,1);
      if ( ++this.index > this.fullStr.length)
      {
        this.index=0;
        clearInterval(this.itv);
        str = this.fullStr;
      }
      this.setState({str });
    },50)
  }



  render()
  {
    let {str} = this.state;

    return (
      <div className="p2">{str}</div>
    )
  }
}
const RandomFontGlitch="@#$%^&*<>_-";