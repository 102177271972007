import React, {Component} from 'react';
import './App.css';
import {Route, Switch} from "react-router-dom";
import MainPage from "./MainPage";
import Facade from "./Facade";
import WatchPage from "./WatchPage";
const ROUTE_ROOTPATH ="(/df)?/";  // 本地是根目录，兼容服务器上带前缀目录

class App extends Component
{


  componentWillUnmount()
  {
    Facade.screenHelper.dispose();
  }

  componentWillMount()
  {
    Facade.screenHelper.init(this);
  }

  render()
  {

    return (
      <div className="App">
        <Switch>
          <Route path={ROUTE_ROOTPATH+"watch.html"} component={()=><WatchPage />} />
          <Route path={ROUTE_ROOTPATH+"flight.html"} component={()=><MainPage fst={2}/>} />
          <Route path={ROUTE_ROOTPATH} component={MainPage}/>
        </Switch>
      </div>
    );
  }
}

export default App;
