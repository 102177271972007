import React, {Component} from 'react';
import "./MainPage.css";
import Facade from "./Facade";
import Utils from "./Utils";
import ChangeResult from "./ChangeResult";
import CrmForm from "./CrmForm";
import IdentityOptions from "./IdentityOptions";
import QuickContact from "./QuickContact";
import FirstScreen from "./FirstScreen";
import BevStatHelper from "./BevStatHelper";
import MusicBtn from "./MusicBtn";

export default class MainPage extends Component
{


  constructor(props)
  {
    super(props);
    if ( props.fst )
    {
      Facade.model.firstScreenType= props.fst;
    }
    this.state = {
      role:-1,
      result:[]
    };
  }

  componentDidMount()
  {

    Facade.bevStatHelper.checkScroll();

  }


  componentWillUnmount()
  {
  }


  beginRole(role)
  {
    this.setState({role,result:null});
  }


  render()
  {
    return (
      <div  className="MainPage">

        <div className={"MenuBtn fst"+Facade.model.firstScreenType} onClick={QuickContact.show.bind(this)}>
          <img src={ require("./res/btn_QR.png") } alt=""/>
        </div>

        <FirstScreen />

        <div className="sectionYdbh" id="sectionYdbh">
          <div className="h1" >世界已然不同</div>
          <div className="h1" >要改变! 才能应对</div>
          <img className="titleImg" src={require("./res/variation.png")} alt=""/>
        </div>

        <div className="sectionRhgb" id="sectionRhgb">
          <div className="h1">如何改变，我能!</div>
          <div className="rhgbRow">
            <div className="rhgbLi" onClick={this.beginRole.bind(this,0)}>
              <img src={require("./res/change_boss.png")} alt=""/>
            </div>
            <div className="rhgbLi" onClick={this.beginRole.bind(this,1)}>
              <img src={require("./res/change_term.png")} alt=""/>
            </div>
            <div className="rhgbLi" onClick={this.beginRole.bind(this,2)}>
              <img src={require("./res/change_new.png")} alt=""/>
            </div>
            <div className="rhgbLi rhgbLiEnd" onClick={this.beginRole.bind(this,3)}>
              <img src={require("./res/change_oneself.png")} alt=""/>
            </div>
          </div>
        </div>

        <IdentityOptions role={this.state.role} onSubmit={(result) => {
          this.setState({result, role: -1});
          Facade.bevStatHelper.enterOnce(BevStatHelper.EVENT_RHGB_DONE)
        }}/>

        <ChangeResult result={this.state.result}/>

        <div className="sectionChengJiu" id="sectionChengJiu">
          <div className="h1">神奇的新工具</div>
          <div className="h1">成就组织和个人</div>
          <div className="chengJiuGrid">
            <img src={require("./res/feishu_chat.png")} alt=""/>
            <img src={require("./res/feishu_video.png")} alt=""/>
            <img src={require("./res/feishu_document.png")} alt=""/>
            <img src={require("./res/feishu_calendar.png")} alt=""/>
            <img src={require("./res/feishu_robot.png")} alt=""/>
            <img src={require("./res/feishu_OKR.png")} alt=""/>
            <img src={require("./res/feishu_serve.png")} alt=""/>
            <img src={require("./res/feishu_app.png")} alt=""/>
          </div>
          <div className="chengjiuDownload" id="sectionKaiqi">
            <div className="h2">开启高效协同办公时代</div>
            <a className="primaryBtn" href={Facade.GOTO_DOWNLOAD_URL}>立即注册</a>
          </div>
        </div>

        {/*表单*/}
        <CrmForm/>

        <div className="sectionAbout" id="sectionAbout">
          <div className="h1">选择我们</div>
          <div className="ourRow">
            <img src={require("./res/our_iuxlabs.png")} alt=""/>
            <img src={require("./res/our_feishu.png")} alt=""/>
          </div>

          <div className="h1">关于我们</div>
            <div className="aboutQrRow">
              {/*<div className="qrLi">*/}
              {/*  <img src={require("./res/QR_force.png")} alt=""/>*/}
              {/*  <span>微信号</span>*/}
              {/*</div>*/}
              <div className="qrLi">
                <img src={require("./res/QR_iuxlabsoa.png")} alt=""/>
                <span>公众号</span>
              </div>
              <div className="qrLi">
                <img src={require("./res/QR_feishu.png")} alt=""/>
                <span>注册飞书</span>
              </div>
            </div>
        </div>


        <div className="pageFooter">
          © iUX Labs 2020 备案号：沪ICP备14048341号-1
        </div>


        <QuickContact />

      </div>);
  }


}




