export default class BevStatHelper
{
  bevMap = new Map();

  constructor()
  {
  }

  static EVENT_WELCOME = "event_01";
  static EVENT_YDBH = "event_02";
  static EVENT_RHGB = "event_03";
  //做了如何改变的身份代入
  static EVENT_RHGB_DONE = "event_03_01";
  //成就组织和个人
  static EVENT_CHENGJIU = "event_04";
  //开启高效协同办公时代
  static EVENT_KAIQI = "event_05";
  //完善信息，获取优先专业咨询
  static EVENT_CRM = "event_06";
  //完善提交了信息
  static EVENT_CRM_DONE = "event_06_01";
  //关于我们
  static EVENT_ABOUT = "event_07";


  // 单次进入统计 (自动去重)
  enterOnce(eventID)
  {
    if (this.bevMap.get(eventID) == true)
    {
      return;
    } else
    {
      if ( window.MtaH5!=null)
      {
        console.log("ScreenHelper/enterOnce", eventID);
        this.bevMap.set(eventID, true);
        window.MtaH5.clickStat(eventID);
      }else{
        console.log("BevStatHelper/enterOnce MtaH5 not ready");
      }
    }
  }

  // 检测是否划到关键统计内容
  checkIsEnter(pageHeight,viewportHeight,scrollHeight)
  {
    let reachedEndY = (viewportHeight  + scrollHeight - 20);
    if ( this.getElementTop("FirstScreen")<reachedEndY )  this.enterOnce(BevStatHelper.EVENT_WELCOME);
    if ( this.getElementTop("sectionYdbh")<reachedEndY )  this.enterOnce(BevStatHelper.EVENT_YDBH);
    if ( this.getElementTop("sectionRhgb")<reachedEndY )  this.enterOnce(BevStatHelper.EVENT_RHGB);
    if ( this.getElementTop("sectionChengJiu")<reachedEndY )  this.enterOnce(BevStatHelper.EVENT_CHENGJIU);
    if ( this.getElementTop("sectionKaiqi")<reachedEndY )  this.enterOnce(BevStatHelper.EVENT_KAIQI);
    if ( this.getElementTop("crm-form-wrapper")<reachedEndY )  this.enterOnce(BevStatHelper.EVENT_CRM);
    if ( this.getElementTop("sectionAbout")<reachedEndY )  this.enterOnce(BevStatHelper.EVENT_ABOUT);
  }

  getElementTop( elementId )
  {
    let ele=document.getElementById(elementId);
    if ( ele==null) return Number.MAX_SAFE_INTEGER;
    return ele.offsetTop;
  }


  checkScroll()
  {
    if (!this.lowEnough()) return this.pollScroll();

  }

  /**
   * 每次调用，将会检测到触发一次为止
   */
  pollScroll()
  {
    setTimeout(this.checkScroll.bind(this), 1000);
  }

  lowEnough()
  {
    let pageHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);
    let viewportHeight = window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight || 0;
    let scrollHeight = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop || 0;
    // console.log(pageHeight);
    // console.log(viewportHeight);
    // console.log(scrollHeight);
    // console.log("BevStatHelper/pageHeight", pageHeight, "viewportHeight", viewportHeight, "scrollHeight", scrollHeight);
    this.checkIsEnter(pageHeight,viewportHeight,scrollHeight);
    //
    return pageHeight - viewportHeight - scrollHeight < 20;
  }




}

