import Notify from "./Notify";
import Facade from "./Facade";

/**
 * Created by rhett on 2019/9/23.
 */
const SERVER_API = "/backend";
export default class Gateway
{

  token = "";


  getQueryVariable(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++)
    {
      var pair = vars[i].split("=");
      if (pair[0] == variable)
      {
        return pair[1];
      }
    }
    return (false);
  }


  //
  gwAlert = alert;

  gwSimpleCall(api, extraArg, cb, errCb)
  {
    let formData = new FormData();
    for (let eachKey in extraArg)
    {
      formData.append(eachKey, extraArg[eachKey]);
    }

    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    fetch(SERVER_API + api, {headers, method: "post", credentials: "include", body: formData})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if (resp.error == 0)
        {
          cb(resp.data);
        } else
        {
          let isSwallow = false;
          if (errCb != null)
            isSwallow = errCb(resp);
          if (!isSwallow) this.gwAlert(resp.message);
        }

      }.bind(this)).catch((error) => {
      this.gwAlert("服务器错误" + error.message);
      console.error(error);
    });

  }

  gwSimpleGet(api, extraArg, cb)
  {
    let url = SERVER_API + api;
    if (extraArg)
    {
      let args = [];
      for (let eachKey in extraArg)
      {
        args.push(eachKey + "=" + extraArg[eachKey]);
      }
      if (args.length)
        url = url + "?" + args.join("&");
    }
    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    fetch(url, {headers, method: "get", credentials: "include"})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if (resp.error == 0)
        {
          cb(resp.data);
        } else
        {
          alert(resp.message);
        }

      }.bind(this)).catch((error) => {
      console.error(error);
    })
  }

  /**
   name：公司名字
   industry：行业
   size：规模
   funs：功能
   contact_name：姓名
   contact_tel：电话
   contact_wechat：微信号
   job：职位
   need_service：0不需要，1需要
   note：其它需求
   */
  guestPost(name,
            industry,
            size,
            funs,
            contact_name,
            contact_tel,
            contact_wechat,
            job,
            need_service,
            note, cb)
  {
    this.gwSimpleCall("/guest/post", {name,
      industry,
      size,
      funs,
      contact_name,
      contact_tel,
      contact_wechat,
      job,
      need_service,
      note}, cb);
  }

}