import React, {Component} from 'react';
import "./IdentityOptions.css";

const choices = [
  ["疫情冲击很大，我想保持团队凝聚力和效率",
    "世界变了，我想重塑组织，建立新的文化",
    "我要让团队围绕一致的目标，持续高产出",
    "我要发现组织内的人才，吸引更多外部人才",
    "传统业务不灵了，我想开创新的业务模式"],
  ["我想帮助公司业务增长，获得更好的绩效"
    , "我想借助数字化，帮助公司传统业务转型",
    "公司处在被颠覆的时代，我想帮助公司开发新业务"]
  , ["我想快速融入团队",
    "我想快速从公司、从同事那里学到本领"],
  ["我是互联网新人，我正在找方向",
    "我是自媒体创业者，我需要连接更多的人一起做事情",
    "我是自媒体KOL，我想找好公司好产品合作变现"]
];
const results = [
  [1,2,3,4,6]

,
    [1,2,3,4,5]

,[1,2,3,4,7]
,[1,2,3,4,8]


];
export default class IdentityOptions extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };

  constructor(props)
  {
    super(props);
    this.state = {
      selectedIndexes: [],
      roleChoices: []
    };
  }

  componentWillReceiveProps(nextProps, nextContext)
  {
    this.setRole(nextProps.role);
  }

  // -1无  0～3 不同角色
  setRole(role)
  {
    // console.log("IdentityOptions/setRole",role    );
    if (-1<role &&  role<choices.length)
    {
      this.setState({
        selectedIndexes: [],
        roleChoices: choices[role]
      })
    }else{
      this.setState({
        selectedIndexes: [],
        roleChoices: []
      });
    }
  }

  componentWillUnmount()
  {
  }

  componentWillMount()
  {
  }

  componentDidMount()
  {    this.setRole(this.props.role);

  }

  render()
  {
    let {roleChoices,selectedIndexes} = this.state;
    if ( roleChoices==null || roleChoices.length<=0)
      return null;
    return (
      <div id="identity-options-wrapper">
        <p className="title">选择目前遇到的问题</p>
        <div className="options" >
          {roleChoices.map((li,i)=>{
            let src;
            if (selectedIndexes.indexOf(i)>-1)
            {
              src=require("./res/select.png");
            }else {
              src=require("./res/unselected.png");
            }
            return (
              <p className="option" key={i} onClick={this.toggleSelection.bind(this,i) }>
                <img src={src}/>
                <span className="text">{li}</span>
              </p>)
          })}


        </div>
        <div className="submit">
          <a onClick={()=>{
            // console.log("IdentityOptions/",this.props.role ,results,results[1]);
            this.props.onSubmit(results[this.props.role]);
          }} className="primaryBtn">提交</a>
        </div>
      </div>
    );
  }

  toggleSelection(index)
  {
    let {selectedIndexes} = this.state;
    let ii=selectedIndexes.indexOf(index);
    if ( ii > -1)
    {
      selectedIndexes.splice(ii,1);
    }else{
      selectedIndexes.push(index);
    }
    this.setState({selectedIndexes});
  }


}