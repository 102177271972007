
import React, {Component} from 'react';
import "./QuickContact.css";
import Facade from "./Facade";

export default class QuickContact extends Component
{

  static _instance ;
  constructor(props)
  {
    super(props);
    this.state = {};
    QuickContact._instance = this;
  }


  componentDidMount()
  {
  }


  static show ()
  {
    QuickContact._instance.setState({show:true});
  }
  onClickClose()
  {
    this.setState({show:false})

  }
  render(){
    if (!this.state.show ) return null;
    return (
      <div className="QuickContact">
        <div className="dialog">
          <div className="dialog-title">
            <p className="close" onClick={this.onClickClose.bind(this)}>
              <img src={require("./res/turn_off.png")}/>
            </p>
          </div>
          <div className="qrRow">
            <div className="qrLi">
              <img src={require("./res/QR_iuxlabsoa.png")} alt=""/>
              <span>我要咨询 关注公众号</span>
            </div>
            <a className="qrLi" href={Facade.GOTO_DOWNLOAD_URL}>
              <img src={require("./res/QR_feishu.png")} alt=""/>
              <span>注册飞书 玩转新工具</span>
            </a>
          </div>
        </div>
      </div>);
  }

}