import React, {Component} from 'react';
import "./MainPage.css";
import Facade from "./Facade";
import Utils from "./Utils";
import ChangeResult from "./ChangeResult";
import CrmForm from "./CrmForm";
import IdentityOptions from "./IdentityOptions";
import QuickContact from "./QuickContact";
import FirstScreen from "./FirstScreen";
import BevStatHelper from "./BevStatHelper";
import MusicBtn from "./MusicBtn";

export default class MainPage extends Component
{


  constructor(props)
  {
    super(props);
    if (props.fst)
    {
      Facade.model.firstScreenType = props.fst;
    }
    this.state = {
      role: -1,
      result: []
    };
  }

  componentDidMount()
  {

    Facade.bevStatHelper.checkScroll();

  }


  componentWillUnmount()
  {
  }


  beginRole(role)
  {
    this.setState({role, result: null});
  }


  render()
  {
    return (
      <div className="WatchPage" style={{width:'100%'}}>
        <video src={require("./res/feishu_iuxlabs.mp4")} loop="loop"
               style={{width:'750px',height:'422px'}}
               autoPlay={false}
               controls={true}
               preload="auto"
               poster={require("./res/feishu_iuxlabs-0001.png")}
        />
      </div>
    );
  }
};