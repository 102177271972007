import React, {Component} from 'react';
import "./MusicBtn.css";

export default class MusicBtn extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {isMute: true};
  }

  componentDidMount()
  {
    this.itv = setInterval(()=>{
      var audioElement = document.getElementById( 'music' );
      if (audioElement)
      {
        this.setState({isMute : audioElement.paused})
        clearInterval( this.itv);
      }
    },500);
  }

  render()
  {
    let {isMute} = this.state;
    return (
      <div className={"MusicBtn "+ (isMute?"mute":"") } onClick={() => {
        if (window.toggleMute())
        {
          this.setState({isMute: true});
        } else
          this.setState({isMute: false});
      }}>
        <img src={require("./res/music_btn.png")} alt=""/>
      </div>);
  }

}