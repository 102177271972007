import React, {Component} from 'react';
import "./CrmForm.css";
import Facade from "./Facade";
import BevStatHelper from "./BevStatHelper";


export default class CrmForm extends Component
{
    static propTypes = {
        //height :PropTypes.number,//高度
    };
    constructor(props)
    {
        super(props);
      this.state = {
        hangye: "",
        gongneng:[],
        zhiwu:"",guimo:"",
        openZixun: false,
        gongsi:"",
        xingming:"",
        shouji:"",
        weixin:"",
        zixun:"",

        formSuccessed: false
      };
    }
    componentWillUnmount()
    {
    }
    componentWillMount()
    {
    }
    componentDidMount()
    {
    }

    checkCanSubmit()
    {
      let {hangye,gongneng,guimo,zhiwu,openZixun   , zixun,weixin,shouji,xingming,gongsi} = this.state;

      return (( hangye && gongneng && gongneng.length && guimo && zhiwu && weixin && shouji && xingming && gongsi )
      // && gongsi.length>=4
        && shouji.length>=11
        // && weixin.length>3
        // && xingming.length>1
      );

    }

    submitForm()
    {
      if ( this.checkCanSubmit() )
      {
        let {hangye,gongneng,guimo,zhiwu,openZixun   , zixun,weixin,shouji,xingming,gongsi} = this.state;

        Facade.gw.guestPost(gongsi,hangye,guimo,gongneng.join(","),xingming,shouji,weixin,zhiwu,openZixun?1:0,zixun,
          ()=>{
              alert("提交成功，您已完善信息");
              this.setState({formSuccessed: true});
              Facade.bevStatHelper.enterOnce(BevStatHelper.EVENT_CRM_DONE);
          })
      }
    }


    render()
    {
      let {formSuccessed} = this.state;
      // formSuccessed=true;
      if ( formSuccessed )
      {
        return (
          <div id="crm-form-wrapper" ref={(ref)=>{
            if(ref && ref.offsetTop)
              window.scrollTo(0, ref.offsetTop);
          }}>
            <p className="title">您已完善信息</p>
              <div className="qrLi">
                <img src={require("./res/QR_force.png")} alt=""/>
                <span>我要咨询 添加微信</span>
              </div>
          </div>
        );
      }
      let {hangye,gongneng,guimo,zhiwu,openZixun   , zixun,weixin,shouji,xingming,gongsi} = this.state;
      let {dShow,dTitle,dList,dVal,dAllowMulty,dCb} = this.state;
        return (

          <div id="crm-form-wrapper">
            <a name="crmform"></a>
            {/*// <!-- 标题 -->*/}
            <p className="title">完善信息，获取优先专业咨询</p>
            {/*// <!-- 表单 -->*/}
            <div className="crm">
              <p className="form-line">
                <input className="form-input" placeholder="公司全称（营业执照名）" onChange={(e)=>{
                  this.setState({gongsi:e.target.value});
                }}/>
              </p>
              <p className={"form-line form-arrow-line "+ (hangye?'fullfill':"")} onClick={()=>{
                this.setState(
                  {dShow:true,
                    dTitle: "所属行业", dList: ChoiceListHangye, dVal: hangye,dAllowMulty:false, dCb: (val) => {
                      this.setState({dShow:false,hangye:val});
                    }
                  }
                  )}}>
                <span>{hangye ||"所属行业"}</span>
                <img src={require("./res/pull_down.png")} />
              </p>
              <p className={"form-line form-arrow-line "+ (guimo?'fullfill':"")} onClick={()=>{
                this.setState(
                  {dShow:true,
                    dTitle: "人员规模", dList: ChoiceListRygm, dVal: guimo,dAllowMulty:false, dCb: (val) => {
                      this.setState({dShow:false,guimo:val});
                    }
                  }
                )}}>
                <span>{guimo || "人员规模"}</span>
                <img src={require("./res/pull_down.png")} />
              </p>
              {/*// <!-- fullfill为填写后的样式 -->*/}
              <p className={"form-line form-arrow-line "+ (gongneng.length>0?'fullfill':"")} onClick={()=>{
                this.setState(
                  {dShow:true,
                    dTitle: "功能选择（可多选）", dList: ChoiceListGongneng, dVal: gongneng,dAllowMulty:true, dCb: (val) => {
                      this.setState({dShow:false,gongneng:val});
                    }
                  }
                )}}>
                <span>{gongneng.join("+") || '功能选择（可多选）'}</span>
                <img src={require("./res/pull_down.png")} />
              </p>
              <p className="form-line ">
                <input className="form-input" placeholder="您的姓名" onChange={(e)=>{
                  this.setState({xingming:e.target.value});
                }}/>
              </p>
              <p className="form-line">
                <input className="form-input" placeholder="手机号" onChange={(e)=>{
                  this.setState({shouji:e.target.value});
                }}/>
              </p>
              <p className="form-line">
                <input className="form-input" placeholder="微信号" onChange={(e)=>{
                  this.setState({weixin:e.target.value});
                }}/>
              </p>

              <p className={"form-line form-arrow-line "+ (zhiwu?'fullfill':"")} onClick={()=>{
                this.setState(
                  {dShow:true,
                    dTitle: "职级", dList: ChoiceListZhiwei, dVal: zhiwu,dAllowMulty:false, dCb: (val) => {
                      if ( val !=ChoiceListZhiwei[2])
                      {
                        //咨询服务给关闭
                        this.setState({dShow:false,zhiwu:val,openZixun:false});
                      }else{
                        this.setState({dShow:false,zhiwu:val});
                      }
                    }
                  }
                )}}>
                <span>{zhiwu || "职级"}</span>
                <img src={require("./res/pull_down.png")} />
              </p>

              {zhiwu == ChoiceListZhiwei[2] &&
              <p className="form-line form-arrow-line" onClick={() => {
                this.setState({openZixun: !openZixun})
              }}>
                <span style={{fontSize: "24px"}}>是否需要咨询服务</span>
                {openZixun && <img src={require("./res/select.png")}/>}
                {!openZixun && <img src={require("./res/unselected.png")}/>}
              </p>
              }
            </div>
            {<div className="note">
              <textarea className="note-input" placeholder="写下您的需求，让我们更好的了解您" onChange={(e)=>{
                this.setState({zixun:e.target.value});
              }}></textarea>
            </div>}

            <div className="submit">
              <button className={"primaryBtn " + (this.checkCanSubmit() ? "" : "disable")}
                      onClick={this.submitForm.bind(this)}>提交
              </button>
            </div>

            <CrmFormDialog show={dShow} title={dTitle} list={dList} val={dVal} allowMulty={dAllowMulty} cb={dCb} cancel={()=>{this.setState({dShow:false})}}/>

          </div>);
    }



}


const ChoiceListHangye =
  [
    "互联网&高科技",
    "信息与通信",
    "金融保险",
    "健康与社会工作",
    "专业服务",
    "建筑与房地产",
    "租赁与中介",
    "文化艺术与娱乐业",
    "环境与公共设施",
    "体育",
    "时尚",
    "教育",
    "制造",
    "食品",
    "交通与物流",
    "批发零售",
    "其它"
  ];
const ChoiceListRygm = [
  "1～15",
  "16～99",
  "100～999",
  "1000以上"
];
const ChoiceListGongneng = [
  "即时沟通",
  "音视频会议",
  "在线文档",
  "智能日历",
  "智能机器人",
  "工作台",
  "服务台",
  "OKR"
];
const ChoiceListZhiwei = ["员工",
  "经理",
  "VP及以上"];


class CrmFormDialog extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      show: false
    };
  }


  componentWillReceiveProps(nextProps, nextContext)
  {
    let {show, title, list, val, allowMulty, cb} = nextProps;
    this.setConfig(show, title, list, val, allowMulty, cb);
  }

  componentDidMount()
  {
    let {show, title, list, val, allowMulty, cb} = this.props;
    this.setConfig(show, title, list, val, allowMulty, cb);
  }

  setConfig(show,title, list, val, allowMulty, cb)
  {
    this.setState({
      show,title, list, val, allowMulty, cb
    });
  }

  onSubmit()
  {
    let {cb, val} = this.state;
    cb(val);
    this.setState({show: false});
  }
  onCancel()
  {
    this.props.cancel();
  }

  render()
  {
    let {show, title, list, val, allowMulty} = this.state;
    if (!show) return null;
    return (
      <div className="form-bottom-dialog">
        <div className="dialog">
          <div className="dialog-title">
            <span>{title}</span>
            <p className="close" onClick={this.onCancel.bind(this)}>
              <img src={require("./res/turn_off.png")}/>
            </p>
          </div>
          <div className="dialog-content">
            {
              list.map((li, i) => {
                let className = "";
                if (val.indexOf(li) > -1)
                {
                  className = "active";
                }
                return (
                  <span key={i} className={className} onClick={()=>{
                    if ( allowMulty)
                    {
                      let ii=val.indexOf(li);
                      if ( ii>-1)
                      {
                        val.splice(ii,1);
                      }else{
                        val.push(li);
                      }
                      this.setState({val})
                    }else{
                      this.setState({val:li})
                    }
                  }}>{li}</span>
                )
              })}
          </div>
          <div className="dialog-footer">
            <a className={"primaryBtn "+ (val.length?"":"disable")} onClick={this.onSubmit.bind(this)}>确定</a>
          </div>
        </div>
      </div>);
  }


}